<template>
  <div class="dashboard-wraper">
    <h4>My Customers</h4>
    <div v-if="!loading">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Properties
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            Agencies
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <table class="table table-condensed table-bordered">
            <thead>
              <tr>
                <th>Property</th>
                <th>Name</th>
                <th>Method</th>
                <th>Contact</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(customer, i) in property_customers" :key="`customer-${i}`">
                <td>{{ customer.property }}</td>
                <td>{{ customer.name }}</td>
                <td>{{ customer.method }}</td>
                <td>{{ customer.contact }}</td>
                <td>{{ customer.date }}</td>
              </tr>
              <tr v-if="property_customers.length == 0">
                <td colspan="5">There is no data to display.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <table class="table table-sm table-bordered">
            <thead>
              <tr>
                <th>Property</th>
                <th>Name</th>
                <th>Method</th>
                <th>Contact</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(customer, i) in agency_customers" :key="`customer-${i}`">
                <td>{{ customer.agency }}</td>
                <td>{{ customer.name }}</td>
                <td>{{ customer.method }}</td>
                <td>{{ customer.contact }}</td>
                <td>{{ customer.date }}</td>
              </tr>
              <tr v-if="agency_customers.length == 0">
                <td colspan="5">There is no data to display.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      property_customers: [],
      agency_customers: [],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios.get("/api/v1/my-account/customers").then((response) => {
        this.property_customers = response.data.property_customers;
        this.agency_customers = response.data.agency_customers;
        this.loading = false;
      });
    },
  },
};
</script>
